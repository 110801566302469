<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
  >
    <v-card>
      <v-card-title>Detalle de la salida</v-card-title>
      <!-- <edit-products-form
        :product="product"
        @close="dialog = false"
      /> -->
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <!-- <v-data-table
                :headers="headers"
                :items="products"
                :loading="fetchingProducts"
                loading-text="Cargando... Por favor, espere"
                no-data-text="No hay datos disponibles"
                :footer-props="{
                  'items-per-page-text': 'Filas por página',
                  pageText: '{0}-{1} de {2}'
                }"
              /> -->
              <output-detail-table
                :id="id.id"
                :total="id.totalAmountCurrencyReference"
                :dialog="value"
              />
            </v-col>
            <p> <b>Autor: </b> {{ id.userName || '' }} {{ id.userLastName || '' }}</p>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  // import EditProductsForm from './EditProductsForm'
  import OutputDetailTable from './OutputDetailTable.vue'
  import { mapState } from 'vuex'
  export default {
    name: 'OutputsDetailDialog',
    components: {
      // EditProductsForm,
      OutputDetailTable,
    },
    props: {
      id: {
        type: Object,
        default () {
          return {}
        },
      },
      value: {
        type: Boolean,
        default () {
          return false
        },
      },
    },
    data () {
      return {
        headers: [
          {
            text: 'Nombre del producto',
            value: 'description',
          },
          {
            text: 'Cantidad',
            value: 'cant',
          },
          {
            text: 'Acciones',
            value: 'actions',
          },
        ],
      }
    },
    computed: {
      ...mapState(['products', 'fetchingProducts']),
      dialog: {
        get () {
          return this.value
        },
        set (v) {
          this.$emit('input', v)
        },
      },
    },
  }
</script>
