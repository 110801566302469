<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card
          icon="mdi-arrow-up-bold"
          title="Salidas"
          class="px-5 py-3"
          color="orange"
        >
          <!-- <pre> {{ warhouseOutputs }} </pre> -->
          <v-data-table
            :headers="headers"
            :items="warhouseOutputs"
            :loading="fetchingWarehouseOutputs"
            loading-text="Cargando... Por favor, espere"
            no-data-text="No hay datos disponibles"
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template
              v-if="showAdd"
              v-slot:[`body.prepend`]="{}"
            >
              <br>
              <tr>
                <th />
                <th>
                  <template>
                    <!-- @click="dialogA = true" -->
                    <v-autocomplete
                      v-model="data.provider"
                      :items="getProviders"
                      item-text="name"
                      return-object
                      :loading="fetchingProviders"
                      :disabled="fetchingProviders"
                      outlined
                      label="Proveedor"
                    />
                  </template>
                </th>
                <th colspan="2">
                  <v-autocomplete
                    v-model="productSelected"
                    label="Productos"
                    :loading="fetchingProducts"
                    :disabled="fetchingProducts"
                    :items="getProducts"
                    item-text="name"
                    no-data-text="No hay coincidencia"
                    return-object
                    outlined
                  />
                  <!-- <v-row>
                    <v-col>
                      <v-text-field
                        v-model="packingCant"
                        type="number"
                        min="1"
                        :rules="requiredFieldRules('Cantidad es requerido')"
                        outlined
                        label="Rollos"
                        required
                        :hint="'Disponible: ' + getCantPackingBySelectProduct"
                        persistent-hint
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="cant"
                        type="number"
                        min="1"
                        :rules="requiredFieldRules('Cantidad es requerido')"
                        outlined
                        label="KG"
                        required
                        persistent-hint
                        :hint="'Disponible :' + getCantbySelectProduct"
                      />
                    </v-col>
                  </v-row> -->
                </th>
                <th>
                  <v-text-field
                    v-model="packingCant"
                    type="number"
                    min="1"
                    :rules="requiredFieldRules('Cantidad es requerido')"
                    outlined
                    label="Rollos"
                    required
                    :hint="'Disponible: ' + getCantPackingBySelectProduct"
                    persistent-hint
                  />
                </th>
                <th>
                  <v-text-field
                    v-model="cant"
                    type="number"
                    min="1"
                    :rules="requiredFieldRules('Cantidad es requerido')"
                    outlined
                    label="KG"
                    required
                    persistent-hint
                    :hint="'Disponible :' + getCantbySelectProduct"
                  />
                </th>

                <!-- <th>
                  <v-textarea
                    v-model="data.guideNumber"
                    outlined
                    name="input-7-4"
                    label="Número de guía"
                    no-resize
                  />
                </th> -->
                <th>
                  <v-text-field
                    v-model="data.description"
                    outlined
                    name="input-7-4"
                    label="Observaciones"
                    no-resize
                  />
                </th>
                <!-- <th /> -->
                <!-- <th /> -->
                <!-- <th /> -->

                <th>
                  <v-btn
                    icon
                    class="transparent-button-icon"
                    type="submit"
                    color="success"
                    :disabled="disabledSave"
                    :loading="fetchingProducts"
                    @click="submit"
                  >
                    <v-icon size="28">
                      mdi-check-all
                    </v-icon>
                  </v-btn>
                </th>
              </tr>
            </template>
            <template v-slot:top>
              <v-row>
                <v-col cols="10">
                  <outputs-table-top />
                </v-col>
                <v-col>
                  <br>
                  <v-btn
                    :color="showAdd ? 'red' : 'orange'"
                    fab
                    large
                    class="v-btn--example"
                    @click="showAdd = !showAdd"
                  >
                    <v-icon v-if="showAdd">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.description`]="{ item: input }">
              <span v-if="input.description">
                {{ input.description }}
              </span>
              <span v-else>
                ...
              </span>
            </template>
            <template v-slot:[`item.total`]="{ item: input }">
              <span>
                {{ input.totalAmountCurrencyReference | formatPrice }}
              </span>
            </template>
            <template v-slot:[`item.tr`]="{}">
              <span> 0 </span>
            </template>
            <template v-slot:[`item.tk`]="{}">
              <span> 0 </span>
            </template>
            <template v-slot:[`item.createdAt`]="{ item: input }">
              {{ new Date(input.createdAt).getDate() }}/{{ new Date(input.createdAt).getMonth() + 1 }}/{{ new Date(input.createdAt).getFullYear() }}
            </template>
            <template v-slot:[`item.actions`]="{ item: item }">
              <outputs-actions
                :item="item"
              />
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import OutputsTableTop from './OutputsTableTop'
  import OutputsActions from './OutputsActions.vue'
  import { TYPE_TRANSACTION } from '../../../../const'
  // import NewProviderDialog from '../warehouses-inputs/NewProviderDialog.vue'
  // import SearchProvidersDialog from '../warehouses-inputs/SearchProvidersDialogs.vue'

  export default {
    name: 'OutputsTable',

    components: {
      OutputsTableTop,
      OutputsActions,
      // NewProviderDialog,
      // SearchProvidersDialog,
    },

    filters: {
      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },
    },

    data () {
      return {
        showAdd: false,
        dialog: false,
        dialogA: false,
        isValidData: false,
        step: 1,
        salesUnits: ['UNIDAD', 'KG', 'M', 'LB'],
        alert: false,
        productSelected: null,
        cant: 20,
        packingCant: 1,
        data: {
          provider: {},
          guideNumber: '',
          description: '',
          products: [],
        },
        headers: [
          {
            text: 'Fecha',
            value: 'createdAt',
          },
          {
            text: 'Código',
            value: 'code',
          },
          {
            text: 'Proveedor',
            value: 'providerName',
          },
          {
            text: 'Total Rollos',
            value: 'totalPacking',
          },
          {
            text: 'Total KG',
            value: 'totalUnits',
          },
          {
            text: 'Total',
            value: 'total',
          },
          {
            text: 'Observaciones',
            value: 'description',
          },
          {
            text: 'Acciones',
            value: 'actions',
            align: 'center',
            sortable: false,
          },
        ],
      }
    },

    computed: {
      ...mapState([
        'fetchingWarehouseOutputs',
        'warhouseOutputs',
        'user',
        'actionsParentBusiness',
        'fetchingProducts',
        'products',
        'actionWarehouse',
        'warehousesInventory',
        'fechingWarehouseInventory',
        'actionEnvironmentData',
        'providers',
        'fetchingProviders',
      ]),

      color () {
        return this.data.provider?.name ? 'success' : 'indigo'
      },

      saleUnit () {
        return this.productSelected !== null ? this.productSelected.salesUnit : '(unidad)'
      },

      packingUnit () {
        return this.productSelected !== null ? this.productSelected.packingUnit : '(empaque)'
      },

      getProviders () {
        return this.providers.filter(item => item.enable)
      },

      getProducts () {
        // // // // // // // // // // // // // // // // // // // // // // // // console.log('output store products', this.products)
        let res = this.products.filter(item => item.status && this.getProdusctAvailable(item.id || item.productId))
        res = res.map(item => {
          item.name = item.codigo + ' - ' + item.description
          item.productId = item.productId ? item.productId : item.id
          delete item.id

          return item
        })

        return res

        // let res = this.warehousesInventory.filter(item => item.quantity_available > 0)

        // res = res.map(item => {
        //   item.description = item.codigo + ' - ' + item.description

        //   return item
        // })

        // return res
      },

      getCantbySelectProduct () {
        let res = this.warehousesInventory.filter(item => item?.productId === this.productSelected?.productId)
        res = res[0]?.quantity_available || 0

        const chargedProduct = this.data.products.filter(item => item.productId === this.productSelected?.productId)
        return res - (chargedProduct[0]?.cant || 0)
      },

      getCantPackingBySelectProduct () {
        let res = this.warehousesInventory.filter(item => item?.productId === this.productSelected?.productId)
        res = res[0]?.quantity_packing_available || 0

        const chargedProduct = this.data.products.filter(item => item.productId === this.productSelected?.productId)
        return res - (chargedProduct[0]?.packingCant || 0)
      },

      businessId () {
        return (this.user.businessId || this.actionsParentBusiness) || null
      },

      total () {
        let total = 0
        this.data.products.forEach((item, i) => {
          // // // // // // // // // // // // // // // // // // // // // // // // console.log(i, item)
          total = total + (item.wholesalePrices * item.cant)
        })
        return total
      },

      disabledSave () {
        let res = false
        // for (const [key] of Object.entries(this.data)) {
        //   res = this.data[key] === false || this.data[key] === null
        // }
        if (
          // this.data.products.length < 1 ||
          // this.data.guideNumber === '' ||
          this.data.provider?.name === ''
        ) {
          res = true
        }
        // // // // // // // // // console.log('ORES', res)
        return res
      },
    },

    methods: {
      ...mapActions([
        'fetchWarehouseOutputs',
        'createWarehouseOutputs',
        'fetchWarehouseInventory',
      ]),

      select (v) {
        // // // // // // // // // // // // console.log('CLIT', v)
        this.data.provider = v
      },

      getProdusctAvailable (id) {
        const filter = this.warehousesInventory.filter(item => item?.productId === id)
        // // // // // // // // // // // // // // // // // // // // // console.log('available', filter)

        // // // // // // // // // // // // // // // // // // // // // // // // console.log('res', res)
        // res = res[0]?.quantity_available > 0

        // const chargedProduct = this.data.products.filter(item => item.productId === this.productSelected?.productId)
        // // // // // // // // // // // // // // // // // // // // // // // // console.log('cP', chargedProduct)
        return filter.length > 0 && filter[0].quantity_available > 0
      },

      addProductList () {
        if (this.cant <= this.getCantbySelectProduct) {
          if (this.productSelected && this.cant) {
            // // // // // // // // // // // // // // // // // // // // // // // // console.log('producto sleccionado', this.productSelected)
            if (this.data.products.filter(item => item.productId === this.productSelected.productId).length >= 1) {
              this.data.products = this.data.products.map(item => item.productId === this.productSelected.productId ? ({
                ...item,
                cant: Number(item.cant) + Number(this.cant),
                packingCant: Number(item.packingCant) + Number(this.packingCant),
              }) : item)
            } else {
              this.data.products.push({ ...this.productSelected, cant: this.cant, packingCant: this.packingCant })
            }
          }
          this.alert = false
          this.productSelected = null
          this.cant = 1
          this.packingCant = 1
        } else {
          this.alert = true
        }
      },

      deleteProductList (id) {
        this.data.products = this.data.products.filter(item => item.productId !== id)
      },

      requiredFieldRules (text) {
        return [
          v => !!v || text,
        ]
      },

      assingMax () {
        this.cant = this.getCantbySelectProduct
      },

      resetData () {
        // for (const [key] of Object.entries(this.data)) {
        //   this.data[key] = '' // Making all keys of the object ''
        // }
        this.data = {
          guideNumber: '',
          provider: {},
          description: '',
          products: [],
        }
      },
      async afterCreate () {
        this.$emit('close')
        this.showAdd = false
        this.resetData()
        // await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
        await this.fetchWarehouseOutputs({ id: this.actionWarehouse })
        await this.fetchWarehouseInventory({ id: this.actionWarehouse })
      },
      async submit (e) {
        e.preventDefault()
        const total = this.total
        if (this.businessId !== null && this.disabledSave === false) {
          const submit = {
            ...this.data,
            products: [{ ...this.productSelected, cant: this.cant, packingCant: this.packingCant }],
            typeTransactionId: TYPE_TRANSACTION.salida,
            userId: this.user.id,
            warehouseId: this.actionWarehouse,
            relatedWarehouseId: null,
            total: total,
            code: this.actionEnvironmentData.code,
          }
          await this.createWarehouseOutputs(submit)
          this.afterCreate()
        }
      },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
