var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"transparent-button-icon",attrs:{"disabled":_vm.fetchingProducts,"color":"light-blue darken-1","icon":""},on:{"click":function($event){_vm.openedEditDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"25"}},[_vm._v(" mdi-magnify-plus-outline ")])],1)]}}])},[_c('span',[_vm._v("Presione para ver el detalle de la salida")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"transparent-button-icon",attrs:{"disabled":_vm.fetchingProducts,"color":"primary","icon":""},on:{"click":_vm.downloadPdf}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"25"}},[_vm._v(" mdi-download ")])],1)]}}])},[_c('span',[_vm._v("Presione para descargar la orden de salida")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"transparent-button-icon",attrs:{"disabled":_vm.fetchingProducts,"color":"secondary","icon":""},on:{"click":_vm.printPdf}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"25"}},[_vm._v(" mdi-printer ")])],1)]}}])},[_c('span',[_vm._v("Presione para imprimir la orden de salida")])]),_c('outputs-detail-dialog',{attrs:{"id":_vm.item},model:{value:(_vm.openedEditDialog),callback:function ($$v) {_vm.openedEditDialog=$$v},expression:"openedEditDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }