var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.warhouseOutputsDetail,"loading":_vm.fetchingWarehouseOutputsDetail,"loading-text":"Cargando... Por favor, espere","no-data-text":"No hay datos disponibles","footer-props":{
      'items-per-page-text': 'Filas por página',
      pageText: '{0}-{1} de {2}'
    }},scopedSlots:_vm._u([{key:"item.cant",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(Math.abs(item.cant))+" ")]}},{key:"item.packingCant",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(Math.abs(item.packingCant))+" ")]}},{key:"item.amountWithoutTax",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.amountWithoutTax))+" ")]}},{key:"item.subtotal",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(Math.abs(item.cant) * item.amountWithoutTax))+" ")]}}],null,true)},[_vm._v(" > ")]),_c('hr'),_c('br'),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('h1',[_vm._v(" Total: ")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('h1',[_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(_vm.formatPrice(_vm.total))+" ")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }