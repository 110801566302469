<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="warhouseOutputsDetail"
      :loading="fetchingWarehouseOutputsDetail"
      loading-text="Cargando... Por favor, espere"
      no-data-text="No hay datos disponibles"
      :footer-props="{
        'items-per-page-text': 'Filas por página',
        pageText: '{0}-{1} de {2}'
      }"
    >
      <template v-slot:[`item.cant`]="{ item }">
        {{ Math.abs(item.cant) }}
      </template>
      <template v-slot:[`item.packingCant`]="{ item }">
        {{ Math.abs(item.packingCant) }}
      </template> >
      <template v-slot:[`item.amountWithoutTax`]="{ item }">
        {{ formatPrice(item.amountWithoutTax) }}
      </template>
      <template v-slot:[`item.subtotal`]="{ item }">
        {{ formatPrice(Math.abs(item.cant) * item.amountWithoutTax) }}
      </template>
    </v-data-table>

    <hr>
    <br>

    <v-row
      justify="end"
    >
      <v-col cols="3">
        <h1>
          Total:
        </h1>
      </v-col>
      <v-col cols="3">
        <h1>
          <span class="green--text">{{ formatPrice(total) }} </span>
        </h1>
      </v-col>
    </v-row>
    <!-- {{ id }} -->
    <!-- <pre>{{ (warhouseInputsDetail) }}</pre> -->
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    name: 'OutputDetailTable',

    props: {
      id: {
        type: Number,
        default () {
          return -1906
        },
      },
      total: {
        type: Number,
        default () {
          return -1906
        },
      },
      dialog: {
        type: Boolean,
        default () {
          return false
        },
      },
    },

    data () {
      return {
        headers: [
          {
            text: 'Codigo del producto',
            value: 'codigo',
          },
          {
            text: 'Nombre del producto',
            value: 'description',
          },
          {
            text: 'Rollos',
            value: 'packingCant',
          },
          // {
          //   text: 'Unidad de empaque',
          //   value: 'packingUnit',
          // },
          {
            text: 'KG',
            value: 'cant',
          },
          {
            text: 'Precio',
            value: 'amountWithoutTax',
          },
          {
            text: 'Total',
            value: 'subtotal',
          },
          // {
          //   text: 'Unidad',
          //   value: 'salesUnit',
          // },
        ],
      }
    },

    computed: {
      ...mapState([
        'fetchingWarehouseOutputsDetail',
        'warhouseOutputsDetail',
      ]),
    },

    watch: {
      async dialog (v) {
        // // // // // // // // // // // // // // // // // // // // // // // // console.log(v)
        if (v) {
          await this.fetchWarehouseOutputsDetail({ id: this.id })
        }
      },
    },

    async mounted () {
      // // // // // // // // // // // // // // // // // // // // // // // // console.log('id', this.id)
      await this.fetchWarehouseOutputsDetail({ id: this.id })
    },

    methods: {
      ...mapActions([
        'fetchWarehouseOutputsDetail',
      ]),

      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },
    },

  }
</script>
